// Navigation

.navbar-default {
  background-color: white;
  border-color: $theme-primary;
  @include sans-serif-font;
  @include transition-all;


  //navbar-brand - logo
  .navbar-brand {
    color: $theme-primary;
    @include sans-serif-font;
    font-weight: 700;
    text-transform: uppercase;
    &:hover,
    &:focus {
      color: darken($theme-primary, 10%);
    }
    img {
      max-height: 50px;
    }
  }

  @media (min-width: 768px) {
   // background-color: transparent;
    border-color: $theme-secondary;

    //navbar-brand - logo
    .navbar-brand {
      color: $text-grey;
      &:hover,
      &:focus {
        color: white;
      }
    }
    &.affix {
      background-color: white;
      border-color: $theme-primary
    }

    .navbar-collapse.navbarStroke ul li a {
      position: relative;
    }

    .navbar-collapse.navbarStroke ul li a:after {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 0;
      content: '.';
      color: transparent;
      background: $theme-primary;
      height: 1px;
    }
    .navbar-collapse.navbarStroke ul li a:hover:after {
      width: 100%;
    }

    .navbar-collapse.navbarStroke ul li a {
      display: block;
      padding: 10px;
      font-weight: 800;
      color: $text-grey;
      text-decoration: none;
      text-transform: uppercase;
    }
    .navbar-collapse.navbarStroke ul li a,
    .navbar-collapse.navbarStroke ul li a:after {
      transition: all .5s;
    }
    .navbar-collapse.navbarStroke ul li a {
      &:hover {
        color: $theme-primary;
      }
    }
  }
}
