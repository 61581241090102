html,
body {
    height: 100%;
    width: 100%;
}

body {
    @include serif-font;
}

hr {
    border-color: $theme-primary;
    border-width: 3px;
    max-width: 50px;
}

hr.light {
    border-color: white;
}

.bg-primary {
    background-color: $theme-primary;
}

.bg-dark {
    background-color: $theme-dark;
    color: white;
}

.text-faded {
    color: rgba(white, .7);
}

section {
    padding: 100px 0;
}

aside {
    padding: 50px 0;
}

.no-padding {
    padding: 0;
}


// Sections

.section-heading {
    margin-top: 0;
}

// Format section
.service-box {
    overflow:hidden; //Fix for bounceIn animation: https://stackoverflow.com/questions/27355163/white-space-below-footer-and-body-in-chrome
    max-width: 400px;
    margin: 50px auto 0;
    @media (min-width: 992px) {
        margin: 20px auto 0;
    }
    p {
        margin-bottom: 0;
    }
}

// Sponsors
.sponsors {
    .row {
        justify-content: space-evenly;

    }
}

@supports (-ms-ime-align:auto) {
  .sponsors {
    .row {
      justify-content: space-around;
    }
  }
}


.portfolio-box {
    position: relative;
    display: block;
    max-width: 650px;
    margin: 0 auto;
    .portfolio-box-caption {
        color: white;
        opacity: 0;
        display: block;
        background: rgba( $theme-primary, .9 );
        position: absolute;
        bottom: 0;
        text-align: center;
        width: 100%;
        height: 100%;
        @include transition-all;
        .portfolio-box-caption-content {
            width: 100%;
            text-align: center;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            .project-category,
            .project-name {
                @include sans-serif-font;
                padding: 0 15px;
            }
            .project-category {
                text-transform: uppercase;
                font-weight: 600;
                font-size: 14px;
            }
            .project-name {
                font-size: 18px;
            }
        }
    }
    &:hover {
        .portfolio-box-caption {
            opacity: 1;
        }
    }
    @media (min-width: 768px) {
        .portfolio-box-caption {
            .portfolio-box-caption-content {
                .project-category {
                    font-size: 16px;
                }
                .project-name {
                    font-size: 22px;
                }
            }
        }
    }
}

// Venue
.venue {
    overflow:hidden;

    i {
        width: 100%;
    }
}

// CTA
.call-to-action {
    h2 {
        margin: 0 auto 20px;
    }
}

.no-gutter > [class*='col-'] {
    padding-right:0;
    padding-left:0;
}


.btn-xl {
    padding: 15px 30px;
}


// Extras
// -- Highlight Color Customization
::-moz-selection {
    color: white;
    text-shadow: none;
    background: $theme-dark;
}

::selection {
    color: white;
    text-shadow: none;
    background: $theme-dark;
}

img::selection {
    color: white;
    background: transparent;
}

img::-moz-selection {
    color: white;
    background: transparent;
}

body {
    -webkit-tap-highlight-color: $theme-dark;
}

