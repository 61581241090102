

h1,
h2,
h3,
h4,
h5,
h6 {
  @include sans-serif-font;
}

p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
}

a {
  @include transition-all;
  color: $theme-primary;
  &:hover,
  &:focus {
    color: darken($theme-primary, 10%);
  }
}

// Bootstrap Overrides
.text-primary {
  color: $theme-primary !important;
}
