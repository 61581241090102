
// Homepage Header

header {
  position: relative;
  width: 100%;
  min-height: auto;
  @include background-cover;
  background-position: center;
  //background-image: url('../img/hero.jpg');
  background-image: url('../img/Rectangle2.jpg');
  text-align: center;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;

  .hero {
    margin: 0 auto;
    padding: 100px 15px 100px;

    h1 {
      font-weight: 700;
      text-transform: uppercase;
      margin-top: 0;
      margin-bottom: 0;
    }

    hr {
      margin: 30px auto;
    }

    p {
      font-weight: 300;
      color: #fff;
      font-size: 18px;
      margin-bottom: 50px;
    }
  }

  @media (min-width: 768px) {
    min-height: 100%;
    margin-top: 0;

    .hero {
      padding: 0 50px;

      h1 {
        font-size: 55px !important;
      }

      p {
        //font-size: 28px;
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
      }
    }

  }


}
